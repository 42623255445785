/* Importing Google Fonts - Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.bodySec {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 95vh;
}

.bgColor{
    background: rgb(0,61,138);
    background: linear-gradient(90deg, rgba(0,61,138,1) 0%, rgba(0,112,186,1) 63%, rgba(0,147,255,1) 100%)!important;
}

#root {
  width: 100%;
  padding: 0.6rem;
}
.mainLogin .contentLogin {
    width: 100%;
    flex: 0 0 100%;
    transition: all 0.5s ease;
}

.login-container {
  margin: 0 auto;
  width: 450px;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.login-container .form-title {
  text-align: center;
  font-size: 1.37rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.login-container .social-login {
  display: flex;
  gap: 1.31rem;
}

.social-login .social-button {
  display: flex;
  gap: 0.81rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.75rem 0;
  border-radius: 0.31rem;
  align-items: center;
  justify-content: center;
  background: #F9F8FF;
  border: 1px solid #D5CBFF;
  transition: 0.3s ease;
}

.social-login .social-button:hover {
  border-color: #5F41E4;
  background: #f1eff9;
}

.social-login .social-button .social-icon {
  width: 23px;
}

.login-container .separator {
  position: relative;
  margin: 1rem 0;
  text-align: center;
  background: #fff;
}

.login-container .separator span {
  z-index: 1;
  font-weight: 500;
  color: #0070ba;
  position: relative;
  background: #fff;
  font-size: 1.06rem;
  padding: 0 0.9rem;
}

.login-container .separator::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background: #a4c5db;
}

.login-form .input-wrapper {
  height: 54px;
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
}

.login-form .input-field {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 1.06rem;
  border-radius: 0.31rem;
  border: 1px solid #a4c5db;
  padding: 0px 1.25rem 0 3.12rem;
  transition: 0.2s ease;
}

.login-form .input-field:focus {
  border-color: #0070ba;
}

.login-form .input-field::placeholder {
  color: #9284c8;
}

.login-form .input-wrapper i {
  position: absolute;
  top: 50%;
  height: 100%;
  display: flex;
  color: #a4c5db;
  align-items: center;
  transform: translateY(-50%);
  transition: 0.2s ease;
}

.login-form .input-wrapper i:not(.eye-icon) {
  left: 0.93rem;
  pointer-events: none;
}

.login-form .input-field:focus ~ i:not(.eye-icon) {
  color: #0070ba;
}

.login-form .input-wrapper i.eye-icon {
  display: none;
  right: 0.93rem;
  color: #0070ba;
  cursor: pointer;
  font-size: 1.25rem;
}

.login-form .input-wrapper .input-field:valid ~ .eye-icon {
  display: flex;
}

.login-form .forgot-password-link {
  display: block;
  width:fit-content;
  margin-top: -0.44rem;
}

.login-form .login-button {
  border: none;
  outline: none;
  width: 100%;
  height: 54px;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 1.19rem;
  border-radius: 0.31rem;
  background: #0070ba;
  transition: 0.3s ease;
}

.login-form .login-button:hover {
  background: #4320df;
}

.login-container .signup-prompt {
  text-align: center;
  font-size: 1.06rem;
  font-weight: 500;
  margin: 1rem 0 0.31rem;
}

.login-container a {
  color: #0070ba;
  font-weight: 500;
  text-decoration: none;
}

.login-container a:hover {
  text-decoration: underline;
}


